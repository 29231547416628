import * as actionTypes from './actionTypes'
import {authService} from "../../api/services/authService";
import jwt_decode from "jwt-decode"
import {setSnack} from "./snack";
import {routes} from "../../utils/routes";


export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
}

export const loginFailure = (errors) => {
    return {
        type: actionTypes.LOGIN_FAILURE,
        errors
    }
}

export const loginSuccess = (token) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        token,
    };
};

const logout = () => {
    return {
        type: actionTypes.LOGOUT
    };
}

export const logoutAction = () => {
    return dispatch => {
        localStorage.removeItem('token');
        dispatch(setAuthRedirectPath(routes.homePage))
        dispatch(logout())
    }
}

export const clearAuthErrors = () => {
    return {

        type: actionTypes.CLEAR_AUTH_ERRORS
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

const finishAuthReady = () => {

    return {
        type: actionTypes.FINISH_INITIAL_LOADING
    };
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path
    }
}

export const loginAction = ({email, password}) => {
    return dispatch => {
        dispatch(loginStart())
        authService.login(email, password)
            .then((response) => {
                const token = response.data.access_token

                const tokenData = jwt_decode(token)
                localStorage.setItem('token', token);

                dispatch(loginSuccess(token))
                dispatch(setAuthRedirectPath(routes.homePage))
            })
            .catch((errorResponse) => {

                if (errorResponse.redirect){
                    dispatch(loginFailure(errorResponse.message))
                    dispatch(setSnack(errorResponse.message, "error"))
                    dispatch(setAuthRedirectPath(errorResponse.redirect + "?email=" + email))
                }
                else if (typeof errorResponse.message === 'object') {
                    dispatch(loginFailure(errorResponse.message))
                } else {
                    dispatch(setSnack(errorResponse.message, "error"))
                    dispatch(loginFailure({}))
                }
            })
    };
};

export const checkAuthState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        // dispatch(loginStart())
        if (token) {
            const tokenData = jwt_decode(token)
            const expirationDate = new Date(tokenData.exp * 1000);
            if (expirationDate <= new Date()) {
                console.log("expired token")
                dispatch(logoutAction());
            } else {
                dispatch(loginSuccess(token))
                // dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
        dispatch(finishAuthReady())
    };
};